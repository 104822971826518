import cn from 'classnames';
import { Link } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { FooterCopyright } from "./Copyright";

export const Footer = props => {
    const footerClasses = cn('footer', {
        'default-footer': props.defaultFooter
    });
    
    return (
        <footer className={footerClasses}>
            <FooterCopyright />
            <nav className="footer-nav">
                <Link to={routes.ATSRulebook}>ATS Rulebook</Link>
                <Link to={routes.termsAndConditions}>Terms & Conditions</Link>
                <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                <Link to={routes.disclaimers}>Disclaimers</Link>
            </nav>
        </footer>
    )
};
